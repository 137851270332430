/** @format */

import React from "react";

const ItEquipment = () => {
  const data = [
    {
      imageLink: "assets/it1.jpeg",
    },
    {
      imageLink: "assets/it2.jpeg",
    },
    {
      imageLink: "assets/IT3.jpeg",
    },
    {
      imageLink: "assets/IT4.png",
    },
    {
      imageLink: "assets/IT5.jpeg",
    },
    {
      imageLink: "assets/IT6.png",
    },
    // {
    //   imageLink: "assets/GASES4.jpg",
    // },
  ];
  return (
    <section className='py-10  sm:py-16 lg:py-24'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 '>
        <div className='max-w-2xl mx-auto text-center '>
          <h2 className='text-3xl font-bold leading-tight text-[#B02A30] sm:text-4xl lg:text-5xl'>
            IT Services & Supplies:
          </h2>
          <p className='max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600'>
            Excella Sourcing provides different kind of and a wide range of IT
            Services & Supplies. We, as Importers, & in collaboration with
            manufacturers, provide supplies such as:
          </p>
        </div>
        <div className='  mt-16  flex gap-3 flex-wrap justify-center'>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>

            <h3 className='text-lg font-semibold text-black  mb-0'>
              IT Equipment & Consultancy
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Project Management
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Manage Services
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Full Stack Development
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Huawei Certified Internetwork Professional
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              HCIP (Routing & switching)
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              CISCO certified network associate
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              CCNA certified services
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              JNCIA-JUNOS certified Services
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              JNCIA-SECURITY certified services
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Certified Network Security specialists
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Digital Marketing & Content Creation
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Graphics Designing, Animation & Video Editing
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Manage Support Services
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Software Development
            </h3>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              E-commerce & E-Marketing solution & Services
            </h3>
          </div>
        </div>
        <div className='container mx-auto p-4 bg-[#ffffff] rounded-lg mt-5 '>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
            {data.map(({ imageLink }, index) => (
              <div
                key={index}
                className=' md:w-[400px] w-full shadow-xl flex justify-center items-center aspect-square transition-all '>
                <img
                  className=' w-4/4 hover:scale-125  transition-all  rounded-md'
                  src={imageLink}
                  alt='gallery'
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ItEquipment;
