/** @format */

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Base from "./components/layout/Base";
import Home from "./page/Home";
import NotFound from "./page/NotFound";
import About from "./page/About";
import ContactUs from "./page/ContactUs";
import Lab from "./page/Lab";
import Hospital from "./page/General_Products/Hospital";
import Livestock from "./page/General_Products/Livestock";
import Mics from "./page/General_Products/Mics";
import Electrical from "./page/Industrial_Products/Electrical";
import Mechanical from "./page/Industrial_Products/Mechanical";
import Textile from "./page/Industrial_Products/Textile";
import Measuring from "./page/Industrial_Products/Measuring";
import Gases from "./page/Industrial_Products/Gases";
import ItEquipment from "./page/Industrial_Products/ItEquipment";
function App() {
  return (
    <BrowserRouter>
      <Base>
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/lab' element={<Lab />} />
          <Route path='/medical' element={<Hospital />} />
          <Route path='/livestock' element={<Livestock />} />
          <Route path='/mics' element={<Mics />} />
          <Route path='/electrical' element={<Electrical />} />
          <Route path='/mechanical' element={<Mechanical />} />
          <Route path='/textile' element={<Textile />} />
          <Route path='/measuring' element={<Measuring />} />
          <Route path='/gases' element={<Gases />} />
          <Route path='/ItEquipment' element={<ItEquipment />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </Base>
    </BrowserRouter>
  );
}

export default App;
