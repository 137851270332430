/** @format */

import React from "react";
import {
  FaXTwitter,
  FaFacebook,
  FaInstagram,
  FaLocationDot,
} from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className='footer-10'>
      <div className='container'>
        <div className='row py-5  no-gutters'>
          <div className='col-md-4 mb-md-0 mb-4 d-flex'>
            <div className='con con-1 w-100 py-5 shadow-md'>
              <div className='con-info w-100 text-center px-3'>
                <div className='icon d-flex align-items-center justify-content-center'>
                  <span>
                    <IoCall />
                  </span>
                </div>
                <div className='text'>
                  Sales Inquiry: <span>+92 322 8519170 </span> <br />
                  Direct Line: <span>+92 312 5185459 </span> <br />
                  Land Line: <span>051-2758302 </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 mb-md-0 mb-4 d-flex'>
            <div className='con con-2 w-100 py-5 shadow-md'>
              <div className='con-info w-100 text-center'>
                <div className='icon d-flex align-items-center justify-content-center'>
                  <span>
                    <MdEmail />
                  </span>
                </div>
                <div className='text'>
                  <span>
                    excellasourcing@gmail.com, <br /> info@excellasourcing.com
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4 mb-md-0 mb-4 d-flex'>
            <div className='con con-3 w-100 py-5 shadow-md'>
              <div className='con-info w-100 text-center px-3'>
                <div className='icon d-flex align-items-center justify-content-center'>
                  <span>
                    <FaLocationDot />
                  </span>
                </div>
                <div className='text'>
                  <span>
                    Office # 19, 3rd Floor, Gulberg Trade Center, <br /> Gulberg
                    Greens Islamabad
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row mt-5 pt-4 border-top'>
          <div className='col-md-6 col-lg-8 mb-md-0 '>
            <p className='copyright mb-0'>
              Copyright ©2024 All rights reserved.
            </p>
          </div>
          <div className='col-md-6 col-lg-4 text-md-end m-auto'>
            <ul className='ftco-footer-social p-0'>
              <li className='ftco-animate'>
                <a
                  href='/'
                  data-toggle='tooltip'
                  data-placement='top'
                  title=''
                  data-original-title='Twitter'>
                  <FaXTwitter />
                </a>
              </li>
              <li className='ftco-animate'>
                <a
                  href='/'
                  data-toggle='tooltip'
                  data-placement='top'
                  title=''
                  data-original-title='Facebook'>
                  <FaFacebook />
                </a>
              </li>
              <li className='ftco-animate'>
                <a
                  href='/'
                  data-toggle='tooltip'
                  data-placement='top'
                  title=''
                  data-original-title='Instagram'>
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='copyright text-center pb-3 mb-0 flex items-center justify-center gap-3'>
          Developed by{" "}
          <a
            href='https://mehassan.netlify.app'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='https://mehassan.netlify.app/assets/logo.png'
              className=' inline-block w-28'
              alt=''
            />
          </a>
          &
          <a
            href='https://www.linkedin.com/in/yasin-sabri/'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src='/assets/yasin.png'
              className=' inline-block w-20'
              alt=''
            />
          </a>{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
