/** @format */

import React, { useEffect, useState } from "react";
import { FaXTwitter, FaFacebook, FaInstagram } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { pathname } = useLocation();

  useEffect(() => {
    // make it as accordion for smaller screens
    if (window.innerWidth > 992) {
      document.querySelectorAll(".navbar .nav-item").forEach((everyitem) => {
        everyitem.addEventListener("mouseover", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.add("show");
            nextEl.classList.add("show");
          }
        });

        everyitem.addEventListener("mouseleave", function (e) {
          let el_link = this.querySelector("a[data-bs-toggle]");

          if (el_link != null) {
            let nextEl = el_link.nextElementSibling;
            el_link.classList.remove("show");
            nextEl.classList.remove("show");
          }
        });
      });
    }
  }, []);
  return (
    <div className=" sticky-top ">
      <header className="topbar">
        <div className="container">
          <div className="row">
            {/* social icon*/}
            <div className="col-sm-12">
              <ul className="social-network">
                <li>
                  <a className="waves-effect waves-dark" href="#">
                    <FaXTwitter />
                  </a>
                </li>
                <li>
                  <a className="waves-effect waves-dark" href="#">
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a className="waves-effect waves-dark" href="#">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <nav className="navbar navbar-expand-lg navbar-dark mx-background-top-linear">
        <div className="container-fluid">
          <a
            className="navbar-brand text-center pl-0 sm:pl-20"
            target="_blank"
            href="/"
            style={{ textTransform: "uppercase" }}
          >
            {" "}
            <img
              src="assets/logo.png"
              className="w-[150px] sm:w-[330px]"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item ">
                <Link
                  className={`nav-link   ${pathname === "/" ? "active" : ""}`}
                  onClick={scrollToTop}
                  to="/"
                >
                  Home
                  <span className="sr-only">(current)</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  onClick={scrollToTop}
                  className={`nav-link   ${
                    pathname === "/about" ? "active" : ""
                  }`}
                  to="/about"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={scrollToTop}
                  className={`nav-link   ${
                    pathname === "/medical" ? "active" : ""
                  }`}
                  to="/medical"
                >
                  Medical Equipment
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  onClick={scrollToTop}
                  className={`nav-link   ${
                    pathname === "/lab" ? "active" : ""
                  }`}
                  to="lab"
                >
                  Laboratory Equipments
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className={`nav-link `}
                  to="electrical"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="flex flex-row items-center gap-2">
                    Industrial Products
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </span>
                </Link>
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item ${
                        pathname === "/electrical" ? "active" : ""
                      }`}
                      to="electrical"
                    >
                      Electrical Equipment
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item ${
                        pathname === "/mechanical" ? "active" : ""
                      }`}
                      to="mechanical"
                    >
                      Mechanical Equipment
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item   ${
                        pathname === "/textile" ? "active" : ""
                      }`}
                      to="textile"
                    >
                      Textile Equipment
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item   ${
                        pathname === "/measuring" ? "active" : ""
                      }`}
                      to="measuring"
                    >
                      Measuring Instruments
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item   ${
                        pathname === "/gases" ? "active" : ""
                      }`}
                      to="gases"
                    >
                      Gases, Chemicals & Glasswares
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item   ${
                        pathname === "/ItEquipment" ? "active" : ""
                      }`}
                      to="ItEquipment"
                    >
                      IT Equipment
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className={`nav-link `}
                  to="hospital"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="flex flex-row items-center gap-2">
                    General Products{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      fill="currentColor"
                      className="bi bi-caret-down-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </span>
                </Link>
                <ul className="dropdown-menu">
                  {/* <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item ${
                        pathname === "/medical" ? "active" : ""
                      }`}
                      to='medical'>
                  Medical Equipment
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item ${
                        pathname === "/livestock" ? "active" : ""
                      }`}
                      to="livestock"
                    >
                      Livestock/Dairy Equipment
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={scrollToTop}
                      className={`dropdown-item ${
                        pathname === "/mics" ? "active" : ""
                      }`}
                      to="mics"
                    >
                      Safety & Furniture
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  onClick={scrollToTop}
                  className={`nav-link   ${
                    pathname === "/contact" ? "active" : ""
                  }`}
                  to="/contact"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
