/** @format */

import React from "react";

const Mechanical = () => {
  const data = [
    {
      imageLink: "assets/PPE1.jpg",
    },
    {
      imageLink: "assets/PPE2.png",
    },
    {
      imageLink: "assets/PPE3.png",
    },
    {
      imageLink: "assets/PPE4.jpg",
    },
    {
      imageLink: "assets/PPE5.png",
    },
    {
      imageLink: "assets/PPE6.jpg",
    },
    {
      imageLink: "assets/1s.jpg",
    },
    {
      imageLink: "assets/2p.jpg",
    },
    {
      imageLink: "assets/3p.jpg",
    },
    {
      imageLink: "assets/4p.jpg",
    },
    {
      imageLink: "assets/5p.jpg",
    },
  ];
  return (
    <section className='py-10  sm:py-16 lg:py-24'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 '>
        <div className='max-w-2xl mx-auto text-center '>
          <h2 className='text-3xl font-bold leading-tight text-[#B02A30] sm:text-4xl lg:text-5xl'>
            Mechanical Equipment
          </h2>
          <p className='max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600'>
            Excella Sourcing provides different kind of and a wide range of
            mechanical supplies. We, as Importers, & in collaboration with
            manufacturers, provide supplies such as:
          </p>
        </div>
        <div className='  mt-16  flex gap-3 flex-wrap justify-center'>
          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2 '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>

            <h3 className='text-lg font-semibold text-black  mb-0'>
              Industrial Gears, Belts & Pumps
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>Gaskets</h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Mechanical Sealings & O-Rings
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Industrial Filters
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Mechanical Parts & Machines
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              OEM & Non OEM Spare Parts
            </h3>
          </div>

          <div className=' px-4 py-3 rounded-md shadow  text-center flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width={16}
              height={16}
              fill='#B02A30'
              className='bi bi-caret-right-fill '
              viewBox='0 0 16 16'>
              <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
            </svg>
            <h3 className='text-lg font-semibold text-black mb-0'>
              Tools & Equipments
            </h3>
          </div>
        </div>
        {/* <div className='container mx-auto p-4 bg-[#ffffff] rounded-lg mt-5 '>
              <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
                {data.map(({ imageLink }, index) => (
                  <div
                    key={index}
                    className=' w-[300px]  shadow-xl flex justify-center items-center aspect-square transition-all '>
                    <img
                      className=' w-3/4 hover:scale-125  transition-all aspect-square object-contain rounded-md'
                      src={imageLink}
                      alt='gallery'
                    />
                  </div>
                ))}
              </div>
            </div> */}
      </div>
    </section>
  );
};

export default Mechanical;
