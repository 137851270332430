/** @format */

import React from "react";

const Lab = () => {
  const data = [
    {
      imageLink: "assets/lab1.jpeg",
    },
    {
      imageLink: "assets/lab2.jpeg",
    },
    {
      imageLink: "assets/lab3.jpeg",
    },
    {
      imageLink: "assets/lab4.jpeg",
    },
    {
      imageLink: "assets/lab5.jpeg",
    },
    {
      imageLink: "assets/lab6.jpeg",
    },
    {
      imageLink: "assets/lab7.jpeg",
    },
  ];
  return (
    <section className='relative pt-24 pb-10 sm:pt-32 sm:pb-16 lg:pb-24'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 relative z-20'>
        <div className=' mx-auto text-center'>
          <h1 className='text-4xl font-bold sm:text-6xl max-w-xl m-auto'>
            <span className='text-[#B02A30]'>Laboratory Equipments</span>
          </h1>
          <p className='px-0 mb-8 mt-4 text-sm text-gray-600 md:text-lg lg:px-24 max-w-4xl mx-auto'>
            We, Excella Sourcing known as a largest instruments supplier
            offering our expertise and services in quality control labs for
            Plastics Industries, Textile Manufacturing Units, Metal Industries,
            Food processing units, Packaging Industries, Pharmaceutical
            industries, Chemical/ petrochemical industries, Oil & Gas sectors,
            Thermal Power Plants, Testing Laboratories of Major Engineering
            Universities in Pakistan and Material Testing Laboratories. Besides
            complete projects on turnkey basis, we have to our credit a long and
            proven record of experience in allied technologies of instruments
            with having varieties and ranging of worldwide manufacturer
            instruments and also supplier of raw materials hence we are fully
            equipped to turn client`s idea into reality.
          </p>
          <p className='px-0 mb-8 text-sm text-gray-600 md:text-lg lg:px-24 max-w-4xl mx-auto'>
            We provide complete solutions to laboratories across Pakistan. We
            provide our clients with a broad spectrum of technologies in the
            fields of analytical, life and material sciences. We provide
            comprehensive quality base services to our valued customer to meet &
            ensure their highest level of satisfaction. We are specialized in
            marketing of wide range of industrial & research based equipment/
            Instruments & machinery for our industrial sectors in Pakistan.
          </p>
        </div>

        <div className='container mx-auto p-4 bg-[#F8F9FE] rounded-lg'>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
            {data.map(({ imageLink }, index) => (
              <div key={index}>
                <img
                  className=' w-full max-w-full rounded-lg '
                  src={imageLink}
                  alt='gallery'
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lab;
