/** @format */

import React from "react";

const Hospital = () => {
  const data = [
    {
      imageLink: "assets/1.jpg",
    },
    {
      imageLink: "assets/2.jpg",
    },
    {
      imageLink: "assets/3.jpg",
    },
    {
      imageLink: "assets/4.jpg",
    },
    {
      imageLink: "assets/5.jpg",
    },
    {
      imageLink: "assets/6.jpg",
    },
    {
      imageLink: "assets/7.jpg",
    },
    {
      imageLink: "assets/8.jpg",
    },
    {
      imageLink: "assets/9.jpg",
    },
    {
      imageLink: "assets/10.jpg",
    },
  ];
  return (
    <section className='py-10  sm:py-16 lg:py-24'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
        <div className='max-w-2xl mx-auto text-center'>
          <h2 className='text-3xl font-bold leading-tight text-[#B02A30] sm:text-4xl lg:text-5xl'>
            Medical Equipment
          </h2>
          <p className='max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600'>
            Excella Sourcing provides different kind of and a wide range of
            Hospital Equipment supplies. We, as Importers, & in collaboration
            with manufacturers, provide supplies such as:
          </p>
        </div>
        <div className='  mt-16   grid lg:grid-cols-3 sm:grid-cols-2  grid-cols-1 gap-4'>
          {/* Items */}
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Medical Equipment
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>ot table</li>
              <li>ot light</li>
              <li>syringe pump</li>
              <li>infusion pump</li>
              <li>video laryngoscope</li>
              <li>patient monitor</li>
              <li>suction machines</li>
              <li>electrosurgical units</li>
              <li>dialysis/ent chairs</li>
              <li>steam sterilizers</li>
              <li>infant incubator</li>
              <li>vein finder</li>
            </ul>
          </div>
          {/* Items */}
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Laboratory Equpuipment
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>Autoclaves</li>
              <li>Medical Refrigertors</li>
              <li>Centrifuges</li>
              <li>Water Baths</li>
              <li>Biosafety Cabinets</li>
              <li>Laminar Air Flow Cabinets</li>
              <li>Fume Hoods</li>
              <li>Shaking Incubator</li>
              <li>Analyzers</li>
              <li>Incubators</li>
            </ul>
          </div>
          {/* Items */}
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Physiotherapy Equipment
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>Ultrasound Therapy</li>
              <li>Electrical Simulation Equipment</li>
              <li>Physio Lasers</li>
              <li>Traction Equipment</li>
            </ul>
          </div>
          {/* Items */}
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Dental Equipment
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>Dental Chairs</li>
              <li>Dental Autoclave</li>
              <li>Imaging Systems </li>
              <li>Hand Pieces</li>
              <li>Air Compressors</li>
            </ul>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Hospital Furniture
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>Medical Couches</li>
              <li>Medical Stretcher</li>
              <li>Hospital Beds With Bedside Table & Overbed Table</li>
              <li>Hospital Trolleys</li>
              <li>Storage Cabinets</li>
              <li>Delivery Beds</li>
              <li>Pediatric Furniture</li>
            </ul>
          </div>
          <div className=' px-4 py-3 rounded-md shadow  flex  justify-start flex-col gap-2'>
            <div className='flex items-center text-center justify-center  gap-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={16}
                height={16}
                fill='#B02A30'
                className='bi bi-caret-right-fill '
                viewBox='0 0 16 16'>
                <path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z' />
              </svg>

              <h3 className='text-lg font-semibold text-black  mb-0'>
                Surgical Instruments
              </h3>
            </div>
            <ul className=' Medical text-base text-black'>
              <li>Gynae Instruments</li>
              <li>Dental Instruments</li>
              <li>General Surgery Instruments</li>
              <li>Orthopedic Instruments</li>
              <li>Procedure Kits</li>
            </ul>
          </div>
        </div>
        {/* <div className='container mx-auto p-4 bg-[#ffffff] rounded-lg mt-5 '>
          <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
            {data.map(({ imageLink }, index) => (
              <div
                key={index}
                className=' w-[300px]  shadow-xl flex justify-center items-center aspect-square transition-all '>
                <img
                  className=' w-3/4 hover:scale-125  transition-all  aspect-square object-contain '
                  src={imageLink}
                  alt='gallery'
                />
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Hospital;
