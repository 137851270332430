/** @format */

import React from "react";

const ManagmentTable = () => {
  return (
    <div className='container mt-16'>
      <h1 className='text-xl font-bold sm:text-5xl max-w-xl m-auto '>
        <span className='text-[#B02A30]'>Company Management Profile</span>
      </h1>
      <table className='w-full flex  flex-wrap  sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5'>
        <thead className='text-white '>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              MANAGING PARTNERS
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex-col flex-no wrap mb-2 sm:mb-0 '>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Aleem bin Ateeq
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              CEO
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              +92 312 5185459
            </td>
          </tr>
        </tbody>
        <thead className='text-white'>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              MARKETING & SALES DIVISION
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Adeel Shoukat
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              GM Operation
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Muhammad Shabbir
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Mgr Marketing
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Khawar Abbasi (MBA)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Mgr Business Development
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Musna Haris (BA)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Sales Executive
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Bilal Ashiq Hussain
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Sales Executive
            </td>
          </tr>
        </tbody>
        <thead className='text-white'>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              AUDIT, ACCOUNTS & LEGAL DIVISION
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Amir Ayub (B.Com)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Manager Accounts & Finance
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Junaid Nadeem- Advocate (LLB)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Manager Legal Affairs
            </td>
          </tr>
        </tbody>
        <thead className='text-white'>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              ENGINEERING DIVISION
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Moin Ghazzanfar (BS-EE)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Electrical Engineer
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Inam Ul Haq (DAE)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Bio-Medical Engineer
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Muhammad Bashir
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Sr. Technician
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Muhammad Imarn (DAE)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Assistant Technician
            </td>
          </tr>
        </tbody>
        <thead className='text-white'>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              IT DIVISION
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Syed Ahsan Naqvi (BS-EE)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              IT & Networking Engineer
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Nauman Ali (BS-CS)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              E- Marketing Expert
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Hameed Ullah (BS-SE)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Social Media Specialist
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Nabeel Afzal (M.Com)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              E-Commerce Expert
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Sadeed Junaid (BS-CS)
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Web Development & Software Designing
            </td>
          </tr>
        </tbody>
        <thead className='text-white'>
          <tr className='bg-[#b02a30] flex flex-col flex-no wrap w-screen sm:table-row  sm:rounded-none mb-2 sm:mb-0'>
            <th className='p-3 text-center' colSpan={3}>
              Admin Staff
            </th>
            {/* <th className='p-3 text-left'>Email</th> */}
          </tr>
        </thead>
        <tbody className='flex-1 sm:flex-none'>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Mr. Wasif Ali
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Manager Coordination
            </td>
          </tr>
          <tr className='flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0'>
            <td className='border-grey-light border hover:bg-gray-100 p-3'>
              Ms. Samina
            </td>
            <td className='border-grey-light border hover:bg-gray-100 p-3 truncate'>
              Co-coordinator / Receptionist
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ManagmentTable;
